import React, { useState, useEffect } from "react"

// components
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import FamiliesGrid from "../components/FamiliesGrid"
import Partners from "../components/Partners"
import EmailSection from "../components/EmailSection"
import Subscribe from "../components/Subscribe"
import Corporates from "../components/Corporates"
import NewsCarousel from "../components/NewsCarousel"

const Home = () => {
  return (
    <>
      <SEO />
      <Hero />
      <FamiliesGrid />
      <EmailSection />
      <Partners />
      <Corporates />
      <NewsCarousel />
      <Subscribe />
    </>
  )
}

export default Home
